import PillarThumbnailTemplate from "../Common/PillarThumbnailTemplate";
import PeopleIconSection from "../People/PeopleIconSection";


  const IconLayout = ({
    config,
    Icons,
    resources,
    TemplateName,
    
  }) => {
    const parse = require("html-react-parser");
    const renderContent = () => {
        switch (TemplateName) {
            case 'Icon_CommonTemplate':
              return   <PeopleIconSection icons={Icons} resources={resources}></PeopleIconSection>;
             case 'Icon_People_Page_Template':
                return <PillarThumbnailTemplate icons={Icons} {...config}></PillarThumbnailTemplate>
            default:
               return <PeopleIconSection icons={Icons} resources={resources}></PeopleIconSection>;
          }
        }

        return (
            <div>
              {renderContent()}
            </div>
          );
  };
  export default IconLayout;
  