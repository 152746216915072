
import FAQTemplate from "../Common/faq-template";
  const FAQLayout = ({
    faqs,
    resources,
    TemplateName
  }) => {
    const parse = require("html-react-parser");
    const renderContent = () => {
      return   <FAQTemplate faqs={faqs}  resources={resources} ></FAQTemplate>;
        }

        return (
            <div>
              {renderContent()}
            </div>
          );
  };
  export default FAQLayout;
  