import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import configJson from '../../../Config.json';
import { useHistory } from "react-router-dom";
import Reaptcha from "reaptcha";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import CommonObj from '../../../components/CommonObj';
import utils from "../../../components/Common/utils";
import ApiWithForm  from "../../../components/Common/ApiWithForm";

import hidePreLoad from "./hidePreLoad";
const Modal = ({ isOpen, onClose, title, children,ThoughtPaperName }) => {
  const validationSchema = Yup.object().shape({
    name: Yup.string().trim().
      required("Please enter the Firstname Lastname")
      .matches(/^[A-Za-z. ]+$/, "Entered Name is invalid"),
    Organisation: Yup.string().trim()
      .required("Please enter the Organization")
      .test('no-dangerous-tags', 'Entered Organization is invalid', value => {     
        const dangerousTags = /<([a-zA-Z][a-zA-Z0-9]*)\b[^>]*>/g;
        return !dangerousTags.test(value);
      }),

    email: Yup.string().trim()
      .required("Please enter the Official email ID")
      .email("Entered Email is invalid")
       .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'Email must be a valid format')
    ,
    contactNumber: Yup.string().trim().
      required("Please enter the Contact number")
      .matches(/^[\d()+]+$/, 'Invalid Contact Number')
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, reset, formState } = useForm(formOptions);
  const { errors } = formState;
  const captchaRef = useRef(null);

  const [domLoaded, setDomLoaded] = React.useState(false);


  const [name, setName] = useState("");
  const [organization, setOrganization] = useState("");
  const [email, setEmail] = useState("");
  const [isNameValid, setIsNameValid] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isContactValid, setIsContactValid] = useState(false);
  const [isOrganizationValid, setIsOrganizationValid] = useState(false);

  const [contactNumber, setContactNumber] = useState("");
  const [token, setToken] = useState("");
  const history=useHistory();

  React.useEffect(() => {
    setTimeout(async () => {
      setDomLoaded(true);
      await utils.sleep(500);
      let list = document.getElementsByTagName("iframe");
      for (let ele of list) {
        ele.sandbox =
          "allow-same-origin allow-scripts allow-popups allow-forms";
      }
    }, 1000);
  }, []);

  if (!isOpen){
    return null;
  };

  const handleName = (event) => {

    let value = event.target.value.replace(/[^a-z] /gi, "").replace(/\s+/g, ' ').trimStart();
    const re = /^[A-Za-z. ]+$/;
    if (value.length == 0) {
      setIsNameValid(false);
      document.getElementById("name-danger").innerHTML = "Please enter the Firstname Lastname";
    }
    else if (!re.test(value)) {
      setIsNameValid(false);
      document.getElementById("name-danger").innerHTML = "Entered Name is invalid";
    }
    else {
      document.getElementById("name-danger").innerHTML = "";
      setIsNameValid(true);
    }
    setName(value);
  };
  
  const handleOrganization = (event) => {
    const result = event.target.value.replace(/\s+/g, ' ').trimStart();
    const re=/<([a-zA-Z][a-zA-Z0-9]*)\b[^>]*>/g;
    if (result.length == 0)
    {
      setIsOrganizationValid(false);
      document.getElementById("org-danger").innerHTML ="Please enter the Organization";
    }
    else if (re.test(result))
    {
        setIsOrganizationValid(false);
        document.getElementById("org-danger").innerHTML ="Entered Organization is invalid";
    }
    else {document.getElementById("org-danger").innerHTML = "";
    setIsOrganizationValid(true);}
    setOrganization(result);
  };
  const handleEmail = (event) => {
    try {
      let domain_name = event.target.value.split("@")[1];
      let domain = domain_name.toLowerCase();
      var regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      var value=event.target.value.trimStart();
      if (!regex.test(value)) {
        setIsEmailValid(false);
        setEmail(value);
        document.getElementById("email-danger").innerHTML = "Entered email is invalid";
        return;
      }
      if ((domain == "gmail.com" || domain == "yahoo.com" || domain == "inbox.com" || domain == "icloud.com" ||
        domain == "mail.com" || domain == "zoho.com" || domain == "aol.com" || domain == "rediff.com")) {
        setIsEmailValid(false);
        document.getElementById("email-danger").innerHTML ="Kindly enter your Official Email Id";
      }
      else {
        setIsEmailValid(true);
        document.getElementById("email-danger").innerHTML = "";
      }
    } catch (e) {
      document.getElementById("email-danger").innerHTML ="Entered Email is invalid";
      setIsEmailValid(false);
    }
    setEmail(value);
  };
  const preventWhiteSpaceOnKeyDown = (e) => {
    if (e.key === " ") {
        e.preventDefault();
    }
}
  function onHandleChangeNumeric(e) {
    let value = e.target.value.replace(/[^\d()+]/g, "").trimStart();
    const re =/^[\d()+]+$/;
    if (value.length == 0) {
      setIsContactValid(false);
      document.getElementById("contact-danger").innerHTML = "Please enter the Contact Number";
    }
    if (value.toString().length < 10 || value.toString().length > 20) {
      setIsContactValid(false);
      document.getElementById("contact-danger").innerHTML =
        "Invalid Contact Number";
    }
    else if (!re.test(value)) {
      setIsContactValid(false);
      document.getElementById("contact-danger").innerHTML =
        "Invalid Contact Number";
    }
    else {
      setIsContactValid(true);
      document.getElementById("contact-danger").innerHTML = "";
    }
    setContactNumber(value);
  }
  const verify = () => {
    captchaRef.current.getResponse().then(res => {
      setToken(res)
    })

  }
  function validateCaptcha() {
    if (!domLoaded) return false;
    try {

      if (token.length == 0) {
        document.getElementById("validCaptcha").innerHTML =
          "Please ensure you are not a robo";
        return false;
      } else {
        document.getElementById("validCaptcha").innerHTML = "";
        return true;
      }
    } catch (e) { }
  }

  const onSubmit =  async (data) => {
    if (!validateCaptcha() || !isEmailValid || !isContactValid || !isNameValid || !isOrganizationValid) return;
    document.querySelector(".preload").style.display = "flex";
    document.querySelector(".preload").style.opacity = '';
    data.token = token;
    data.thoughtPaperName=ThoughtPaperName;
    var documentURL= document.getElementsByClassName("download-thoughtPaperBtn")[0].value;
    const [res] = await Promise.all([ApiWithForm(data,CommonObj.Endpoints.SaveUserInfo)]);
    window.scrollTo(0, 0);
    if (res != null) {
      downloadFile(documentURL);
    }
  };
  const downloadFile = async (documentURL) => {
    document.querySelector(".preload").style.display = "flex";
    document.querySelector(".preload").style.opacity = '';
    const response = await fetch(CommonObj.Endpoints.DownloadThoughtPaperPdf, {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
      },
      body: JSON.stringify({ DocumentURL: documentURL }),
  });
    const blob =  await response.blob();
    const link = document.createElement('a');
    link.href =  window.URL.createObjectURL(blob);
    link.download = "RRD_GOC_Thought Papers.pdf";
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.scrollTo(0, 0);
    history.push("/ThoughtPapers-Thankyou");
    hidePreLoad();
};

  return (
    <div className="modal-overlay" >
      <div className="modal-container" onClick={(e) => e.stopPropagation()}>
        <div className="modal-header">
          <h3>{title}</h3>
          <button className="modal-close-btn" onClick={onClose}>×</button>
        </div>
        <div className="modal-body">
          <form className="form" id="UserInforForm" onSubmit={handleSubmit(onSubmit)}>
            <div className="input-group">
              <input  {...register("name")} name="name" className="required" id="name" type="text" value={name}
                onChange={handleName} minLength={1} maxLength={50} placeholder="Name" />
              <span id="name-danger" className="text-danger">{errors.name?.message}</span>
            </div>
            <div className="input-group">
            <input type="text"{...register("Organisation")} name="Organisation" className="required" id="Organization"
                  value={organization} onChange={handleOrganization} minLength={1} maxLength={50} placeholder="Organization"/>
                <span id="org-danger" className="text-danger">
                  {errors.Organisation?.message}
                </span>
            </div>
            <div className="input-group">
               <input type="email" {...register("email")} name="email" className="required" id="email"
                value={email} onChange={handleEmail} onPasteCapture={handleEmail} onKeyDown={preventWhiteSpaceOnKeyDown}
                placeholder="Email Address" maxLength={50} /> 
 
              <span id="email-danger" className="text-danger">
                {errors.email?.message}
              </span>
            </div>
            <div className="input-group">
              <input type="tel" {...register("contactNumber")} name="contactNumber"
                className="required" id="contactNumber" value={contactNumber} minLength={10} maxLength={20}
                onChange={onHandleChangeNumeric} onPasteCapture={onHandleChangeNumeric} placeholder="Phone Number" />
              <span id="contact-danger" className="text-danger">
                {errors.contactNumber?.message}
              </span>
            </div>
            <div className="form__fields recaptcha-field captcha" async>
              {domLoaded && (
                <Reaptcha sitekey={configJson.captchaSiteKey} ref={captchaRef} onVerify={verify}  ></Reaptcha>
              )}
              <span id="validCaptcha" className="text-danger">
                {errors.captcha?.message}
              </span>
            </div>

            <div className="download-btn-container">
              <button className="download-btn" style={{ backgroundColor: '#a50050' }} type="submit" onClick={() => validateCaptcha()}>
                Download Now
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Modal;
