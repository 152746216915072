import React, { useEffect, useState } from "react";
import Scroll from "./Scroll";
import AbTriangle from "./AbTriangle";
import ContactForm from "./ContactForm";

const Hero = (props) => {
  /* NOSONAR */
  const [domLoaded, setDomLoaded] = useState(false);
  useEffect(() => {
    setTimeout(() => setDomLoaded(true), 500);
  }, []);

  const parse = require("html-react-parser");
  let content;

  if (props.carouselList != undefined && props.carouselList.length > 0) {
    /* NOSONAR */
    content = (
      <div className="hero__wrapper hero__parallax landingpage">
        <div className="pin" role="presentation"></div>
        <AbTriangle></AbTriangle>
        <Scroll></Scroll>
        <section className="container">
          <div className="herocarousel__holder">
            {props.carouselList && props.carouselList.map((c, i) => 
                 {
                /* NOSONAR */
                return (
                  <div className={"heroSlider heroSlider" + i} key={c.id}>
                    <h2 className="color-white">{c.title}</h2>
                    {!!c.shortDesc && (
                      <h3 className="color-white">
                        {domLoaded && parse(c.shortDesc)}
                      </h3>
                    )}
                  </div>
                );
              })}
          </div>
          <div className="carousel-trigger__holder">
            {props.carouselList.map((c, i) => {
              /* NOSONAR */
              return (
                <button
                  className={"ctrigger ctrigger" + i}
                  data-hc={i}
                  aria-label={i}
                  key={c.id}
                >
                  <span>{i}</span>
                </button>
              );
            })}
          </div>
          <ContactForm resources={props.resources}></ContactForm>
        </section>
      </div>
    );
  } else if (props.page == "people") {
    content = (
      <div className="hero__wrapper hero__parallax">
        <div className="pin"></div>
        <AbTriangle></AbTriangle>

        <section className="container">
          <div id="hero" className="hero">
            <div className="hero__content">
              <div className="title__content">
                {props.category && (
                  <h3 className="title__tag color-white light">{props.category}</h3>
                )}

                {props.heading1 && (
                  <h2 className="color-white bold hero__title">
                    {parse(props.shortDesc)}
                  </h2>
                )}
              </div>
            </div>
          </div>

          {props.heading1 !=
            "Because it’s time to optimize connections across the entire customer journey" &&
            (props.contactUs ?? true) && (
              <ContactForm resources={props.resources}></ContactForm>
            )}
        </section>
      </div>
    );
  } else {
    content = (
      <div className="hero__wrapper hero__parallax">
        <div className="pin"></div>
        <AbTriangle></AbTriangle>

        <div className="container">
          <div id="hero" className="hero">
            <div className="hero__content">
              <div className="title__content">
                {props.category && (
                  <h3 className="title__tag color-white light">{props.category}</h3>
                )}

                {props.heading1 && (
                  <h2 className="color-white bold hero__title">{props.heading1}</h2>
                )}

                {props.desc && <p className="color-white">{parse(props.desc)}</p>}
              </div>
            </div>
          </div>

          {(props.contactUs ?? true) && (
            <ContactForm resources={props.resources}></ContactForm>
          )}
        </div>
      </div>
    );
  }

  return <div async>{content}</div>;
};
export default Hero;
