import React, { useEffect, useState } from 'react';
import Hero from '../Common/Hero';
import hidePreLoad from '../../assets/js/Components/hidePreLoad';
import CommonObj from '../CommonObj';
import { Helmet } from 'react-helmet';
import Section from '../Section/Section';
import '../../components/loader.css';
import PreLoad from '../Common/preload';
import CommonFunc from '../../assets/js/Components/CommonFunc';
import Parallax from '../Common/Parallax';
import parallax from '../../assets/js/Components/parallax';
import { scrollRefresh } from '../../assets/js/Components/parallax';
import StaticResourceText from '../Format/static-resource-text';
import BreadCrumb from '../Common/breadcrumb';
const ThoughtPapersThankyou = (props) => {

    const [page, setPage] = useState();
    const fetchData = async () => {
        let pageName = CommonObj.PageNameList.ThoughtPapersThankyou;
        let res = await fetch(CommonObj.Endpoints.GetPage, {
            method: 'POST',
            body: JSON.stringify(pageName),
            headers: {
                'Content-Type': 'application/json',
            }
        })
        let page = await res.json()
        setPage(page);
        CommonFunc(page);
    }


    useEffect(() => {
       fetchData();
       
    }, []);

    useEffect(() => {
        if (page != null) {
            parallax();
            scrollRefresh();
          }
        window.scrollTo(0, 0);
        hidePreLoad();
    });

    if (page != null) {
        const parse = require('html-react-parser');
        let heroCarouselList = page.contents.filter(x => x.type == CommonObj.ContentType.Carousels);
      /* NOSONAR */
        return (
            <>
            <div className='tp-thankyou'>
                     <Section PageName={page.name} async>
                        <Helmet>
                            <title>{page.title}</title>
                            <meta name="description" content={page.metaDescription} />
                            <meta name="keywords" content={page.metaKeywords} />
                        </Helmet>
                        <Parallax pageheroimage={page.image ? page.image : ''}></Parallax>
                         <Hero landingpage={false} carouselList={heroCarouselList}  heading1={page.shortDesc && parse(page.shortDesc)} resources={props.resources} contactUs={false}></Hero>
                         <BreadCrumb page={page?.name}/>
                         <StaticResourceText resources={props.resources} reskey={"THANKYOU_CONTENT"}></StaticResourceText>
                         </Section> 
                         </div>
            </>

        )
    }
    else {
        return (
            <>
            <PreLoad></PreLoad>
            </>
        )

       
    }
}



export default ThoughtPapersThankyou;