
import { useState } from "react";
import PillarThumbnailTemplate from "../Common/PillarThumbnailTemplate";
import InsuranceServicesIconSection from "../Smart-work-solutions/insuranceservices-icon-section";
const ThumbnailLayout = ({
  config,
  Thumbnails,
  resources,TemplateName
}) => {
  const[showLearnMore, setShowLearnMore] = useState(false)
  const parse = require("html-react-parser");
  const renderContent = () => {
    switch (TemplateName) {
        case 'Thumbnail_CommonTemplate':
            return  <PillarThumbnailTemplate thumbnails={Thumbnails} {...config} showLearnMore={showLearnMore}></PillarThumbnailTemplate>
        case 'Thumbnail_Insurance_Page_Template':
                return <InsuranceServicesIconSection icons={Thumbnails} resources={resources} {...config} ></InsuranceServicesIconSection>
      default:
        return <PillarThumbnailTemplate thumbnails={Thumbnails} {...config}></PillarThumbnailTemplate>
    }
  }

  return (
    <div>
      {renderContent()}
    </div>
  );
};
export default ThumbnailLayout;
