import Hero from "../Common/Hero";
import PeopleCarouselSection from "../People/PeopleCarouselSection";

const CarouselLayout = ({
    config,
    Carousels,
    resources,
    TemplateName
  }) => {
    const parse = require("html-react-parser");
    const renderContent = () => {
        switch (TemplateName) {
            case 'Carousel_CommonTemplate':
              return <Hero carouselList={Carousels} resources={resources} {...config} ></Hero>;
            case 'Carousel_People_PAGE_Template':
                return <PeopleCarouselSection  contents={Carousels}></PeopleCarouselSection>;
            default:
               return <Hero carouselList={Carousels} resources={resources} {...config}></Hero>;
          }
        }

        return (
            <div>
              {renderContent()}
            </div>
          );
  };
  export default CarouselLayout;
  