
import './globals.css';
import React,{ useState ,useEffect,useRef} from "react";
import Header from './components/Common/Header';
import MainSection from './components/MainSection/MainSection';
import SwiperCore, { Autoplay } from 'swiper';
import Lenis from '@studio-freight/lenis';
import Modernizr from "../src/assets/js/vendor/modernizr-custom"


const App = ()  =>{ 
  SwiperCore.use([Autoplay])
  const lenis = useRef(null);

  useEffect(() => {
    if (!(Modernizr.touchevents || Modernizr.forcetouch) && document.documentElement.clientWidth >= 1023){
    lenis.current = new Lenis({
      duration: 2, 
      easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)), 
      smooth: true,
      smoothTouch: true, 
    });
    const animate = (time) => {
      lenis.current.raf(time);
      requestAnimationFrame(animate);
    };

    requestAnimationFrame(animate);

    return () => {
      lenis.current.destroy();
    };
  }
  }, []);

  return (
    <>
    <Header/> 
    <MainSection/>
    
    </>
    
  );
}

export default App;
