import React, { useState, useEffect } from 'react';
import CommonObj from '../CommonObj';
import heroCarousel from '../../assets/js/Components/heroCarousel';
import carousel from '../../assets/js/Components/carousel';
import faq from '../../assets/js/Components/faq';
import { collaspeMenu } from '../../assets/js/Components/navigation';
import hidePreLoad from '../../assets/js/Components/hidePreLoad';
import parallax, { scrollRefresh } from '../../assets/js/Components/parallax';
import resizeWindow from '../../assets/js/Components/resizeWindow';
import videoInit from '../../assets/js/Components/video';
import formInit from '../../assets/js/Components/formInit';
import Parallax from '../Common/Parallax';
import Hero from '../Common/Hero';
import TestimonialExcerptSection from '../Common/TestimonialExcerptSection';
import TestimonialTemplate from '../Common/TestimonialTemplate';
import BlogTemplate from '../Common/blog-template';
import FAQTemplate from '../Common/faq-template';
import PageNotFound from '../Common/PageNotFound';
import { Helmet } from 'react-helmet';
import Section from '../Section/Section';
import PanelTemplate from '../Common/panel-template';
import InfographicsTemplate from '../Common/InfographicsTemplate';
import PillarThumbnailTemplate from "../Common/PillarThumbnailTemplate";
// import BlogSection from "../../components/global-brand-solutions/BlogSection";
import { CircleLoader } from 'react-spinners';
import '../../components/loader.css'
import PreLoad from '../Common/preload';
import CommonFunc from '../../assets/js/Components/CommonFunc';
import BreadCrumb from '../Common/breadcrumb';


function SWS({ resources }) {

    const [page, setPage] = useState();
    const [loading, setLoading] = useState(true);
    const [size, setSize] = useState([0, 0]);
    
    const fetchData = async () => {
        let pageName = CommonObj.PageNameList.SWSOverview;
        console.log(JSON.stringify(pageName));
        console.log(CommonObj.Endpoints.GetPage);
        let res = await fetch(CommonObj.Endpoints.GetPage, {
            method: 'POST',
            body: JSON.stringify(pageName),
            headers: {
                'Content-Type': 'application/json',
            }
        })
        let page = await res.json()
        setPage(page);
        CommonFunc(page);
    }
    function PageScroller()
    {
      const timer = setTimeout(() => 
      {
        setLoading(false);
      }, 3000);
    }

    useEffect(() => {
        // fetch call used to be here
        fetchData();
        collaspeMenu();
        PageScroller();
      
  
    }, []);

    useEffect(() => {
        if (page != null) {
            heroCarousel();
            carousel();
            faq();
            formInit();
            parallax();
            videoInit();
        }
        window.scrollTo(0, 0);
        hidePreLoad();
        resizeWindow();
    }, [loading])

    if (page != null) {
        let landingpage = true;
        let faqs = page.contents.filter(x => x.type == CommonObj.ContentType.FAQS);
        let blogs = page.contents.filter(x => x.type == CommonObj.ContentType.Blogs);
        const parse = require('html-react-parser');
        let thumbnails = page.contents.filter(x => x.type == CommonObj.ContentType.Thumbnails);
        let panels = page.contents.filter(x => x.type == CommonObj.ContentType.Panels);
        let heroCarouselList = page.contents.filter(x => x.type == CommonObj.ContentType.Carousels);
        let infographics = page.contents.filter(x => x.type == CommonObj.ContentType.Infographics);
        let testimonials = page.contents.filter(x => x.type == CommonObj.ContentType.Testimonials);
        let casestudies = page.contents.filter(x => x.type == CommonObj.ContentType.CaseStudies);

        return (
            <>
                {loading ? (<PreLoad></PreLoad>) : (
                     <Section PageName={page.name} async>
                        <Helmet>
                            <title>{page.title}</title>
                            <meta name="description" content={page.metaDescription} />
                            {/* <link rel="canonical" href={process.env.baseDomainUrl + asPath} /> */}
                        </Helmet>
                        <Parallax pageheroimage={page.image ? page.image : ''}></Parallax>
                        <Parallax pageFixedImage={page.backgroundImage ? page.backgroundImage : ''}></Parallax>
                        <Hero landingpage={landingpage} heading1={page.heading} carouselList={heroCarouselList} resources={resources}></Hero>
                                  <BreadCrumb page={page?.name}/>


                        <div className="bg-white mt-1">
                            <section className="container">
                                <div className="inner">
                                    <div className="center-content para90">
                                        {page.longDesc && parse(page.longDesc)}
                                        {!!infographics.length && <InfographicsTemplate infographics={infographics} ></InfographicsTemplate>}
                                    </div>
                                </div>
                            </section>
                        </div>
                        {!!panels.length && <PanelTemplate panels={panels}></PanelTemplate>}
                        {!!thumbnails.length && <PillarThumbnailTemplate thumbnails={thumbnails} showLearnMore={true}></PillarThumbnailTemplate>}
                        {!!testimonials.length && <TestimonialExcerptSection testimonials={testimonials} resources={resources}></TestimonialExcerptSection>}
                        {!!casestudies.length && <TestimonialTemplate testimonials={casestudies} resources={resources}></TestimonialTemplate>}
                        {!!blogs.length && <BlogTemplate bgblue={false} alignleft={false} blogs={blogs} notopspace={false} resources={resources}></BlogTemplate>}
                        {!!faqs.length && <FAQTemplate faqs={faqs} notopspace={false} resources={resources}></FAQTemplate>}
                    </Section>
                )}
            </>
        )
    }
    else {
        return (

            <>
            <PreLoad></PreLoad>
                {/* {loading ? (<PreLoad></PreLoad>) : (
                    <PageNotFound></PageNotFound>
                )} */}
            </>
        )
    }
}

// This gets called on every request
// export async function getServerSideProps({ req, res }) {
//     let pageName = CommonObj.PageNameList.SWSOverview;
//     const [page, resources] = await Promise.all([
//       ApiReturnJson(pageName, CommonObj.Endpoints.GetPage),
//       ApiReturnJson({}, CommonObj.Endpoints.GetResource)
//     ])

//     // Pass data to the page via props
//     return { props: { page, resources } }
// }

export default SWS;