import React, {  } from "react";
import {
  // Navigation,
  Pagination,
  A11y,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import Anchor from "../Icons/Anchor";
import Date from "../Format/Date";
import StaticResourceText from "../Format/static-resource-text";

const TestimonialTemplate = ({ bottomspace, testimonials, resources,isCsTitle, csTitle}) => {
  const parse = require("html-react-parser");

  return (
    <div className={(bottomspace ? "container-mb" : "") + " bg-gray"} async>
      <section className="container">
        {/* ***********************************************************
         ********* Section Title & Desktop Viewmore button *********
         *********************************************************** */}
        <div className="container__title" async>
          <h2 className="align-center">
            <StaticResourceText
              resources={resources}
              reskey={"CLIENT_SS_TSM-TITLE"}
            ></StaticResourceText>
             </h2>
       
        </div>
        {(isCsTitle ?? true) && (
             <StaticResourceText
             resources={resources}
             reskey={csTitle}
           ></StaticResourceText>
          )}


        {/* ***********************************************************
         ********* Carousel *********
         *********************************************************** */}

        <div className="holder-carousel" async>
          <Swiper
            modules={[Pagination, A11y]}
            spaceBetween={50}
            slidesPerView={1}
            // navigation
            pagination={{ clickable: true }}
            // onSwiper={(swiper) => console.log(swiper)}
            // onSlideChange={() => console.log('slide change')}
          >
            {testimonials &&
              testimonials.map((testimonial) => {
                return (
                  <SwiperSlide
                    key={testimonial.image + "_" + testimonial.title}
                  >
                    <div className="carousel" async>
                      <div className="carousel__img">
                        <img
                          src={testimonial.image}
                          alt={testimonial.title}
                          srcSet=""
                        />
                      </div>
                      <div className="carousel__content flow">
                        <p className="color-primary carousel__title--1">
                          {parse(testimonial.title)}
                        </p>
                        <h3 className="color-black carousel__title--2">
                          {testimonial.shortDesc &&
                            parse(testimonial.shortDesc)}
                        </h3>
                        <p className="carousel__title--3">
                          {testimonial.date && (
                            <Date dateString={testimonial.date}></Date>
                          )}
                        </p>
                        {testimonial.anchorlink && (
                          <Anchor
                          className="success-story"
                            anchorText="VIEW SUCCESS STORY"
                            icon={true}
                            bgblue={true}
                            link={"/casestudies" + testimonial.anchorlink}
                          ></Anchor>
                        )}
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
          </Swiper>
        </div>

        {/* *************************************************
         ********* Small devices viewmore button *********
         ************************************************* */}
      </section>
    </div>
  );
};
export default TestimonialTemplate;
