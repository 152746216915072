import ResourceTemplate from "../Common/ResourceTemplate";
import TestimonialTemplate from "../Common/TestimonialTemplate";

const CaseStudyLayout = ({
    config,
    CaseStudies,
    resources,
    TemplateName
  }) => {
    const parse = require("html-react-parser");
    const renderContent = () => {
        switch (TemplateName) {
            case 'CaseStudy_CommonTemplate':
              return <TestimonialTemplate testimonials={CaseStudies}  resources={resources} {...config} ></TestimonialTemplate>;
            case 'CaseStudy_Resoure_PAGE_Template':
                return <ResourceTemplate smallthumbnails={CaseStudies} {...config}></ResourceTemplate>;
            default:
               return <TestimonialTemplate testimonials={CaseStudies}  resources={resources}  {...config}></TestimonialTemplate>;
          }
        }

        return (
            <div>
              {renderContent()}
            </div>
          );
  };
  export default CaseStudyLayout;
  